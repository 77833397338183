import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Date: any;
};

export type Query = {
  __typename?: 'Query';
  say: Scalars['String'];
  currentUser?: Maybe<User>;
  comment?: Maybe<Comment>;
  company?: Maybe<Company>;
  jobAd?: Maybe<JobAd>;
  jobApplication?: Maybe<JobApplication>;
  review?: Maybe<Review>;
  salary?: Maybe<Salary>;
  user?: Maybe<User>;
  userProfile?: Maybe<UserProfile>;
  vote?: Maybe<Vote>;
  resource?: Maybe<Resource>;
  resourceTopic?: Maybe<ResourceTopic>;
  resourceAuthor?: Maybe<ResourceAuthor>;
  highlightedResources: Array<Resource>;
  resourceTopics: Array<ResourceTopic>;
  searchResourcesByTopic: ResourceConnection;
  searchRecentResources: ResourceConnection;
  searchCompanies: CompanyConnection;
  companyNameSuggestions: Array<Scalars['String']>;
  searchJobAds: JobAdConnection;
  wroteAReview: RewardStatus;
};


export type QueryCommentArgs = {
  id: Scalars['ID'];
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryJobAdArgs = {
  id: Scalars['ID'];
};


export type QueryJobApplicationArgs = {
  id: Scalars['ID'];
};


export type QueryReviewArgs = {
  id: Scalars['ID'];
};


export type QuerySalaryArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryVoteArgs = {
  id: Scalars['ID'];
};


export type QueryResourceArgs = {
  id: Scalars['ID'];
};


export type QueryResourceTopicArgs = {
  id: Scalars['ID'];
};


export type QueryResourceAuthorArgs = {
  id: Scalars['ID'];
};


export type QueryHighlightedResourcesArgs = {
  audienceType?: Maybe<Scalars['String']>;
};


export type QueryResourceTopicsArgs = {
  audienceType?: Maybe<Scalars['String']>;
};


export type QuerySearchResourcesByTopicArgs = {
  id: Scalars['ID'];
  searchText?: Maybe<Scalars['String']>;
  pageNum?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  audienceType?: Maybe<Scalars['String']>;
};


export type QuerySearchRecentResourcesArgs = {
  searchText?: Maybe<Scalars['String']>;
  pageNum?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  audienceType?: Maybe<Scalars['String']>;
};


export type QuerySearchCompaniesArgs = {
  searchText?: Maybe<Scalars['String']>;
  pageNum?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryCompanyNameSuggestionsArgs = {
  partialCompanyName: Scalars['String'];
  onlyCompaniesWithProfiles?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchJobAdsArgs = {
  pageNum?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  claimWroteAReview: RewardStatus;
  castVote?: Maybe<CastVotePayload>;
  resourceLike?: Maybe<ResourceLikePayload>;
  createCompany?: Maybe<CreateCompanyPayload>;
  createReview?: Maybe<CreateReviewPayload>;
  createSalary?: Maybe<CreateSalaryPayload>;
  createJobAd?: Maybe<CreateJobAdPayload>;
  createUserProfile?: Maybe<UserProfilePayload>;
  updateUserProfile?: Maybe<UserProfilePayload>;
  applyToJobAd?: Maybe<ApplyToJobAdPayload>;
  flagReview?: Maybe<FlagReviewPayload>;
};


export type MutationClaimWroteAReviewArgs = {
  phoneNumber: Scalars['String'];
  paymentMethod: PaymentMethod;
};


export type MutationCastVoteArgs = {
  input: CastVoteInput;
};


export type MutationResourceLikeArgs = {
  input: ResourceLikeInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateReviewArgs = {
  input: CreateReviewInput;
};


export type MutationCreateSalaryArgs = {
  input: CreateSalaryInput;
};


export type MutationCreateJobAdArgs = {
  input: CreateJobAdInput;
};


export type MutationCreateUserProfileArgs = {
  input: UserProfileInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UserProfileInput;
};


export type MutationApplyToJobAdArgs = {
  input: ApplyToJobAdInput;
};


export type MutationFlagReviewArgs = {
  input: FlagReviewInput;
};

export type FlagReviewInput = {
  reviewId: Scalars['ID'];
  reason: Scalars['String'];
  explanation: Scalars['String'];
};

export type FlagReviewPayload = {
  __typename?: 'FlagReviewPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCompanyInput = {
  name: Scalars['String'];
  contactEmail: Scalars['String'];
  yearEstablished?: Maybe<Scalars['Int']>;
  numEmployees?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  locations: Array<LocationInput>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  websiteURL?: Maybe<Scalars['String']>;
  descriptionOfCompany?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  city: Scalars['String'];
  address: Scalars['String'];
  industrialHub?: Maybe<Scalars['String']>;
};

export type CreateCompanyPayload = {
  __typename?: 'CreateCompanyPayload';
  company?: Maybe<Company>;
};

export type UserProfileInput = {
  fullName: Scalars['String'];
  phoneNumber: Scalars['String'];
  city: Scalars['String'];
  neighborhood?: Maybe<Scalars['String']>;
  workExperiences?: Maybe<Array<Maybe<WorkExperienceInput>>>;
  skills: Array<Maybe<Scalars['String']>>;
  certificatesAndLicences?: Maybe<Array<Maybe<Scalars['String']>>>;
  englishProficiency: LanguageProficiencies;
  highestLevelOfEducation: EducationLevels;
  availability: Array<Maybe<Scalars['String']>>;
  availabilityComments?: Maybe<Scalars['String']>;
  longTermProfessionalGoal?: Maybe<Scalars['String']>;
};

export type WorkExperienceInput = {
  jobTitle: Scalars['String'];
  companyName: Scalars['String'];
  city: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  experienceDescription: Scalars['String'];
};

export type UserProfilePayload = {
  __typename?: 'UserProfilePayload';
  userProfile?: Maybe<UserProfile>;
};

export type CreateReviewInput = {
  companyName: Scalars['String'];
  reviewTitle: Scalars['String'];
  location: LocationInput;
  jobTitle: Scalars['String'];
  numberOfMonthsWorked: Scalars['Float'];
  contractType: ContractType;
  employmentStatus: EmploymentStatus;
  pros: Scalars['String'];
  cons: Scalars['String'];
  wouldRecommendToOtherJobSeekers: Scalars['Boolean'];
  healthAndSafety: Scalars['Int'];
  managerRelationship: Scalars['Int'];
  workEnvironment: Scalars['Int'];
  benefits: Scalars['Int'];
  overallSatisfaction: Scalars['Int'];
  additionalComments?: Maybe<Scalars['String']>;
  referredBy?: Maybe<Scalars['String']>;
};

export type CreateReviewPayload = {
  __typename?: 'CreateReviewPayload';
  review?: Maybe<Review>;
};

export type CreateSalaryInput = {
  companyName: Scalars['String'];
  location: LocationInput;
  jobTitle: Scalars['String'];
  incomeType: IncomeType;
  /** In units of pesos. */
  incomeAmount: Scalars['Float'];
  gender?: Maybe<Gender>;
};

export type ShiftInput = {
  /** The time of day that this job starts in the local time of the job site. */
  startTime?: Maybe<Scalars['String']>;
  /** The time of day that this job ends in the local time of the job site. */
  endTime?: Maybe<Scalars['String']>;
  /** The day of week that this job starts. (0 - 6; Sunday is 0) */
  startDay?: Maybe<Scalars['Int']>;
  /** The day of week that this job ends. (0 - 6; Sunday is 0) */
  endDay?: Maybe<Scalars['Int']>;
};

export type CreateSalaryPayload = {
  __typename?: 'CreateSalaryPayload';
  salary?: Maybe<Salary>;
};

export enum ContractType {
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME',
  Internship = 'INTERNSHIP',
  Temporary = 'TEMPORARY',
  Contractor = 'CONTRACTOR'
}

export enum LanguageProficiencies {
  NativeLanguage = 'NATIVE_LANGUAGE',
  Fluent = 'FLUENT',
  Conversational = 'CONVERSATIONAL',
  Basic = 'BASIC',
  NoProficiency = 'NO_PROFICIENCY'
}

export enum EducationLevels {
  SomeHighSchool = 'SOME_HIGH_SCHOOL',
  HighSchool = 'HIGH_SCHOOL',
  SomeCollege = 'SOME_COLLEGE',
  CollegeDegree = 'COLLEGE_DEGREE'
}

export enum EmploymentStatus {
  Former = 'FORMER',
  Current = 'CURRENT'
}

export type CreateJobAdInput = {
  jobTitle: Scalars['String'];
  jobDescription: Scalars['String'];
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  certificatesAndLicences?: Maybe<Array<Maybe<Scalars['String']>>>;
  contractType: ContractType;
  minimumEducation?: Maybe<EducationLevels>;
  minimumEnglishProficiency?: Maybe<LanguageProficiencies>;
  shifts: Array<Maybe<ShiftInput>>;
  locations: Array<LocationInput>;
  salaryType?: Maybe<IncomeType>;
  /** In units of pesos. */
  salaryMin?: Maybe<Scalars['Float']>;
  /** In units of pesos. */
  salaryMax?: Maybe<Scalars['Float']>;
};

export type CreateJobAdPayload = {
  __typename?: 'CreateJobAdPayload';
  jobAd?: Maybe<JobAd>;
};

export type ApplyToJobAdInput = {
  jobAdId: Scalars['ID'];
  jobTitle: Scalars['String'];
  companyId: Scalars['String'];
  numReviews: Scalars['Int'];
  fullName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  city: Scalars['String'];
  neighborhood?: Maybe<Scalars['String']>;
  workExperiences?: Maybe<Array<Maybe<WorkExperienceInput>>>;
  skills: Array<Maybe<Scalars['String']>>;
  certificatesAndLicences?: Maybe<Array<Maybe<Scalars['String']>>>;
  englishProficiency: LanguageProficiencies;
  highestLevelOfEducation: EducationLevels;
  availability: Array<Maybe<Scalars['String']>>;
  availabilityComments?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
};

export type ApplyToJobAdPayload = {
  __typename?: 'ApplyToJobAdPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type CastVoteInput = {
  /** True to vote positively or favorably. False to vote negatively. Null to remove a vote. */
  isUpvote?: Maybe<Scalars['Boolean']>;
  subjectId: Scalars['ID'];
};

export type CastVotePayload = {
  __typename?: 'CastVotePayload';
  vote?: Maybe<Vote>;
};

export type ResourceLikeInput = {
  resourceSlug: Scalars['String'];
  isResourceLiked: Scalars['Boolean'];
};

export type ResourceLikePayload = {
  __typename?: 'ResourceLikePayload';
  resource?: Maybe<Resource>;
};

export type ResourceConnection = {
  __typename?: 'ResourceConnection';
  nodes: Array<Resource>;
  totalCount: Scalars['Int'];
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  nodes: Array<Company>;
  totalCount: Scalars['Int'];
};

export type JobAdConnection = {
  __typename?: 'JobAdConnection';
  nodes: Array<JobAd>;
  totalCount: Scalars['Int'];
};

export enum RewardStatus {
  CanEarn = 'CAN_EARN',
  CanClaim = 'CAN_CLAIM',
  Claimed = 'CLAIMED',
  Ineligible = 'INELIGIBLE'
}

export enum PaymentMethod {
  Paypal = 'PAYPAL',
  Xoom = 'XOOM',
  Swap = 'SWAP'
}

/** The kinds of things that can be commented on. */
export type CommentParent = Comment | Review;

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  /** What was said in this comment. */
  content: Scalars['String'];
  /** The date and time this was created. */
  created?: Maybe<Scalars['DateTime']>;
  /** The user who wrote this. */
  author: User;
  /** The thing this comment is about or the comment that this one is responding to. */
  parent: CommentParent;
  /** Responses to this comment. */
  children: Array<Comment>;
  /** The votes cast on this comment. */
  votes: Array<Vote>;
};


export type CommentChildrenArgs = {
  pageNum?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type CommentVotesArgs = {
  pageNum?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type Resource = {
  __typename?: 'Resource';
  id: Scalars['ID'];
  slug: Scalars['String'];
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  resourceImageURL: Scalars['String'];
  topicName?: Maybe<Scalars['String']>;
  /** Who the resource is intended for (EMPLOYERS or WORKERS) */
  audienceType: Scalars['String'];
  authorId: Scalars['Int'];
  /** If an Resource is highlighted show it in the highlighted resources section */
  isHighlighted: Scalars['Boolean'];
  author?: Maybe<ResourceAuthor>;
  /** The number of times an resource has been liked */
  numberOfLikes: Scalars['Int'];
  /** A boolean for whether the current user has liked the resource or not */
  isLikedByCurrentUser: Scalars['Boolean'];
  publishDate: Scalars['DateTime'];
};

export type ResourceAuthor = {
  __typename?: 'ResourceAuthor';
  id: Scalars['ID'];
  authorName?: Maybe<Scalars['String']>;
  authorCompanyName?: Maybe<Scalars['String']>;
  authorImageURL?: Maybe<Scalars['String']>;
  /** A short biography on the author */
  authorBio?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  websiteURL?: Maybe<Scalars['String']>;
  /** Location can either be an address or the city */
  location?: Maybe<Scalars['String']>;
};

export type ResourceTopic = {
  __typename?: 'ResourceTopic';
  id: Scalars['ID'];
  topicName: Scalars['String'];
  iconImageURL: Scalars['String'];
  /** Who the topic is intended for (EMPLOYERS or WORKERS) */
  audienceType: Scalars['String'];
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  name: Scalars['String'];
  contactEmail: Scalars['String'];
  yearEstablished?: Maybe<Scalars['Int']>;
  numEmployees?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  locations: Array<Location>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  websiteURL?: Maybe<Scalars['String']>;
  descriptionOfCompany?: Maybe<Scalars['String']>;
  dateJoined?: Maybe<Scalars['DateTime']>;
  numFlags?: Maybe<Scalars['Int']>;
  avgStarRatings?: Maybe<StarRatings>;
  percentRecommended?: Maybe<Scalars['Float']>;
  avgNumMonthsWorked?: Maybe<Scalars['Float']>;
  /** Reviews about this company. */
  reviews: Array<Review>;
  /** Number of reviews about this company. */
  numReviews: Scalars['Int'];
  /** Advertisements for jobs at this company. */
  jobAds: Array<JobAd>;
  /** Number of advertisements for jobs at this company. */
  numJobAds: Scalars['Int'];
  /** Salaries reported to be earned at this company. */
  salaries: Array<Salary>;
  /** Number of salaries reported to be earned at this company. */
  numSalaries: Scalars['Int'];
  /** URL for the company icon image */
  companyIconURL?: Maybe<Scalars['String']>;
  salaryStats: Array<CompanySalaryStats>;
};


export type CompanyReviewsArgs = {
  pageNum?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type CompanyJobAdsArgs = {
  pageNum?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type CompanySalariesArgs = {
  pageNum?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type CompanySalaryStats = {
  __typename?: 'CompanySalaryStats';
  companyName: Scalars['String'];
  jobTitle: Scalars['String'];
  /** In units of pesos. */
  totalAvgPay: Scalars['Float'];
  /** In units of pesos. */
  totalMaxPay: Scalars['Float'];
  /** In units of pesos. */
  totalMinPay: Scalars['Float'];
  /** The number of salaries for a specific job title at a company */
  numSalariesJobTitle: Scalars['Int'];
};

export type JobAd = {
  __typename?: 'JobAd';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  jobTitle: Scalars['String'];
  jobDescription: Scalars['String'];
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  certificatesAndLicences?: Maybe<Array<Maybe<Scalars['String']>>>;
  contractType: ContractType;
  minimumEducation?: Maybe<EducationLevels>;
  minimumEnglishProficiency?: Maybe<LanguageProficiencies>;
  shifts: Array<Maybe<Shift>>;
  locations: Array<Location>;
  salaryType?: Maybe<IncomeType>;
  /** In units of pesos. */
  salaryMin?: Maybe<Scalars['Float']>;
  /** In units of pesos. */
  salaryMax?: Maybe<Scalars['Float']>;
  /** Link to an external job post if it exists */
  externalJobPostURL?: Maybe<Scalars['String']>;
  /** Whether a job post has been archived/delted or not */
  isArchived: Scalars['Boolean'];
  /** The company that is hiring. */
  company: Company;
};

export type JobApplication = {
  __typename?: 'JobApplication';
  id: Scalars['ID'];
  fullName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  city: Scalars['String'];
  neighborhood?: Maybe<Scalars['String']>;
  workExperiences?: Maybe<Array<Maybe<WorkExperience>>>;
  skills: Array<Maybe<Scalars['String']>>;
  certificatesAndLicences?: Maybe<Array<Maybe<Scalars['String']>>>;
  englishProficiency: LanguageProficiencies;
  highestLevelOfEducation: EducationLevels;
  availability: Array<Maybe<Scalars['String']>>;
  availabilityComments?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  jobAd: JobAd;
  company: Company;
};

export type Review = {
  __typename?: 'Review';
  id: Scalars['ID'];
  title: Scalars['String'];
  jobTitle: Scalars['String'];
  location: Location;
  numberOfMonthsWorked: Scalars['Int'];
  contractType: Scalars['String'];
  employmentStatus: Scalars['String'];
  pros: Scalars['String'];
  cons: Scalars['String'];
  wouldRecommendToOtherJobSeekers: Scalars['Boolean'];
  starRatings: StarRatings;
  additionalComments?: Maybe<Scalars['String']>;
  /** The date and time this was created. */
  created?: Maybe<Scalars['DateTime']>;
  upvotes?: Maybe<Scalars['Int']>;
  downvotes?: Maybe<Scalars['Int']>;
  /** The company being reviewed */
  company?: Maybe<Company>;
  /** Comments about this review. */
  comments: Array<Comment>;
  /** The vote that the currently logged in user cast on this review. */
  currentUserVote?: Maybe<Vote>;
};


export type ReviewCommentsArgs = {
  pageNum?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export enum IncomeType {
  YearlySalary = 'YEARLY_SALARY',
  MonthlySalary = 'MONTHLY_SALARY',
  WeeklySalary = 'WEEKLY_SALARY',
  DailySalary = 'DAILY_SALARY',
  HourlyWage = 'HOURLY_WAGE'
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE'
}

export type Salary = {
  __typename?: 'Salary';
  id: Scalars['ID'];
  jobTitle: Scalars['String'];
  location: Location;
  incomeType: IncomeType;
  /** In units of pesos. */
  incomeAmount: Scalars['Float'];
  /** The date and time this was created. */
  created?: Maybe<Scalars['DateTime']>;
  /** The company that paid this salary. */
  company?: Maybe<Company>;
};

/** The roles a user can play. */
export enum UserRole {
  Worker = 'WORKER',
  Company = 'COMPANY',
  CompanyUnverified = 'COMPANY_UNVERIFIED'
}

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  /** The user's username, as would be typed in the login field. */
  username?: Maybe<Scalars['String']>;
  /** The user's role. */
  role: UserRole;
  /** The company that this user is administering. Will be null if this user does not or can not admin a company. */
  company?: Maybe<Company>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  phoneNumber: Scalars['String'];
  city: Scalars['String'];
  neighborhood?: Maybe<Scalars['String']>;
  workExperiences?: Maybe<Array<Maybe<WorkExperience>>>;
  skills: Array<Maybe<Scalars['String']>>;
  certificatesAndLicences?: Maybe<Array<Maybe<Scalars['String']>>>;
  englishProficiency: LanguageProficiencies;
  highestLevelOfEducation: EducationLevels;
  availability: Array<Maybe<Scalars['String']>>;
  availabilityComments?: Maybe<Scalars['String']>;
  longTermProfessionalGoal?: Maybe<Scalars['String']>;
};

export type WorkExperience = {
  __typename?: 'WorkExperience';
  jobTitle: Scalars['String'];
  companyName: Scalars['String'];
  city: Scalars['String'];
  startDate: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  experienceDescription: Scalars['String'];
};

/** The kinds of things that can be voted on. */
export type VoteSubject = Comment | Review;

export type Vote = {
  __typename?: 'Vote';
  id: Scalars['ID'];
  /** True if this vote is positive or favorable. False if this vote is negative. Null if this vote is neutral or the author has not voted. */
  isUpvote?: Maybe<Scalars['Boolean']>;
  /** The user who cast this vote. */
  author: User;
  /** The thing voted on. */
  subject: VoteSubject;
};

export type Location = {
  __typename?: 'Location';
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  industrialHub?: Maybe<Scalars['String']>;
};

export type Shift = {
  __typename?: 'Shift';
  /** The time of day that this job starts in the local time of the job site. */
  startTime?: Maybe<Scalars['String']>;
  /** The time of day that this job ends in the local time of the job site. */
  endTime?: Maybe<Scalars['String']>;
  /** The day of week that this job starts. (0 - 6; Sunday is 0) */
  startDay?: Maybe<Scalars['Int']>;
  /** The day of week that this job ends. (0 - 6; Sunday is 0) */
  endDay?: Maybe<Scalars['Int']>;
};

export type StarRatings = {
  __typename?: 'StarRatings';
  healthAndSafety: Scalars['Float'];
  managerRelationship: Scalars['Float'];
  workEnvironment: Scalars['Float'];
  benefits: Scalars['Float'];
  overallSatisfaction: Scalars['Float'];
};



export type CompanyNameInputQueryVariables = Exact<{
  partialCompanyName: Scalars['String'];
}>;


export type CompanyNameInputQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'companyNameSuggestions'>
);

export type JobPostingFragment = (
  { __typename?: 'JobAd' }
  & Pick<JobAd, 'id' | 'created' | 'jobTitle' | 'jobDescription' | 'skills' | 'certificatesAndLicences' | 'contractType' | 'minimumEducation' | 'minimumEnglishProficiency' | 'externalJobPostURL' | 'isArchived' | 'salaryType' | 'salaryMin' | 'salaryMax'>
  & { shifts: Array<Maybe<(
    { __typename?: 'Shift' }
    & Pick<Shift, 'startDay' | 'endDay' | 'startTime' | 'endTime'>
  )>>, locations: Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'city' | 'address' | 'industrialHub'>
  )>, company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'descriptionOfCompany' | 'numEmployees' | 'industry' | 'websiteURL' | 'companyIconURL'>
    & { locations: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'city' | 'address' | 'industrialHub'>
    )> }
    & CompanyProfileOverviewSectionFragment
    & CompanyProfileReviewsSectionFragment
    & CompanyProfileSalariesSectionFragment
  ) }
);

export type SearchSuggestionsQueryVariables = Exact<{
  partialCompanyName: Scalars['String'];
}>;


export type SearchSuggestionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'companyNameSuggestions'>
);

export type VoteButtonsMutationVariables = Exact<{
  input: CastVoteInput;
}>;


export type VoteButtonsMutation = (
  { __typename?: 'Mutation' }
  & { castVote?: Maybe<(
    { __typename?: 'CastVotePayload' }
    & { vote?: Maybe<(
      { __typename?: 'Vote' }
      & Pick<Vote, 'id' | 'isUpvote'>
    )> }
  )> }
);

export type VoteButtonsFragment = (
  { __typename?: 'Review' }
  & Pick<Review, 'id'>
  & { currentUserVote?: Maybe<(
    { __typename?: 'Vote' }
    & Pick<Vote, 'id' | 'isUpvote'>
  )> }
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'role'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    )> }
  )> }
);

export type ApplyToJobAdMutationVariables = Exact<{
  input: ApplyToJobAdInput;
}>;


export type ApplyToJobAdMutation = (
  { __typename?: 'Mutation' }
  & { applyToJobAd?: Maybe<(
    { __typename?: 'ApplyToJobAdPayload' }
    & Pick<ApplyToJobAdPayload, 'success'>
  )> }
);

export type GetJobTitleAndCompanyIdQueryVariables = Exact<{
  jobAdId: Scalars['ID'];
}>;


export type GetJobTitleAndCompanyIdQuery = (
  { __typename?: 'Query' }
  & { jobAd?: Maybe<(
    { __typename?: 'JobAd' }
    & Pick<JobAd, 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'numReviews'>
    ) }
  )> }
);

export type CompanyProfileReviewFragment = (
  { __typename?: 'Review' }
  & Pick<Review, 'id' | 'title' | 'jobTitle' | 'numberOfMonthsWorked' | 'pros' | 'cons' | 'wouldRecommendToOtherJobSeekers' | 'additionalComments' | 'created'>
  & { location: (
    { __typename?: 'Location' }
    & Pick<Location, 'city' | 'address' | 'industrialHub'>
  ), starRatings: (
    { __typename?: 'StarRatings' }
    & Pick<StarRatings, 'healthAndSafety' | 'managerRelationship' | 'workEnvironment' | 'benefits' | 'overallSatisfaction'>
  ) }
  & VoteButtonsFragment
);

export type CompanyProfileSalaryStatsFragment = (
  { __typename?: 'CompanySalaryStats' }
  & Pick<CompanySalaryStats, 'jobTitle' | 'totalAvgPay' | 'totalMaxPay' | 'totalMinPay' | 'numSalariesJobTitle'>
);

export type CompanyRatingsComponentFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'percentRecommended' | 'avgNumMonthsWorked'>
  & { avgStarRatings?: Maybe<(
    { __typename?: 'StarRatings' }
    & Pick<StarRatings, 'healthAndSafety' | 'managerRelationship' | 'workEnvironment' | 'benefits' | 'overallSatisfaction'>
  )> }
);

export type CompanyProfileJobsSectionFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'name' | 'numJobAds'>
  & { jobAds: Array<(
    { __typename?: 'JobAd' }
    & JobPostingFragment
  )> }
);

export type CompanyProfileOverviewSectionFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'name' | 'descriptionOfCompany'>
);

export type CompanyProfileReviewsSectionFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'name' | 'numReviews'>
  & { reviews: Array<(
    { __typename?: 'Review' }
    & CompanyProfileReviewFragment
  )> }
  & CompanyRatingsComponentFragment
);

export type CompanyProfileSalariesSectionFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'name' | 'numSalaries'>
  & { salaryStats: Array<(
    { __typename?: 'CompanySalaryStats' }
    & CompanyProfileSalaryStatsFragment
  )> }
);

export type CompanyProfileSummaryQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type CompanyProfileSummaryQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'numEmployees' | 'industry' | 'websiteURL' | 'companyIconURL'>
    & { locations: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'city' | 'address' | 'industrialHub'>
    )>, avgStarRatings?: Maybe<(
      { __typename?: 'StarRatings' }
      & Pick<StarRatings, 'overallSatisfaction'>
    )> }
  )> }
);

export type CompanyProfileJobTabQueryVariables = Exact<{
  companyId: Scalars['ID'];
  pageNum: Scalars['Int'];
  pageSize: Scalars['Int'];
}>;


export type CompanyProfileJobTabQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'numJobAds'>
    & { jobAds: Array<(
      { __typename?: 'JobAd' }
      & JobPostingFragment
    )> }
  )> }
);

export type CompanyProfileOverviewTabQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type CompanyProfileOverviewTabQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & CompanyProfileOverviewSectionFragment
    & CompanyProfileReviewsSectionFragment
    & CompanyProfileJobsSectionFragment
    & CompanyProfileSalariesSectionFragment
  )> }
);

export type CompanyProfileReviewTabQueryVariables = Exact<{
  companyId: Scalars['ID'];
  pageNum: Scalars['Int'];
  pageSize: Scalars['Int'];
}>;


export type CompanyProfileReviewTabQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'numReviews'>
    & { reviews: Array<(
      { __typename?: 'Review' }
      & CompanyProfileReviewFragment
    )> }
    & CompanyRatingsComponentFragment
  )> }
);

export type CompanyProfileSalaryTabQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type CompanyProfileSalaryTabQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'numSalaries'>
    & { salaryStats: Array<(
      { __typename?: 'CompanySalaryStats' }
      & CompanyProfileSalaryStatsFragment
    )> }
  )> }
);

export type CompanySearchPageQueryVariables = Exact<{
  searchText: Scalars['String'];
  pageNum: Scalars['Int'];
  pageSize: Scalars['Int'];
}>;


export type CompanySearchPageQuery = (
  { __typename?: 'Query' }
  & { searchCompanies: (
    { __typename?: 'CompanyConnection' }
    & Pick<CompanyConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Company' }
      & CompanySearchResultFragment
    )> }
  ) }
);

export type CompanySearchResultFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'industry' | 'numEmployees' | 'descriptionOfCompany' | 'numReviews' | 'numJobAds' | 'numSalaries' | 'companyIconURL'>
  & { avgStarRatings?: Maybe<(
    { __typename?: 'StarRatings' }
    & Pick<StarRatings, 'overallSatisfaction'>
  )>, locations: Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'city' | 'address' | 'industrialHub'>
  )> }
);

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany?: Maybe<(
    { __typename?: 'CreateCompanyPayload' }
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'contactEmail' | 'yearEstablished' | 'numEmployees' | 'industry' | 'contactPhoneNumber' | 'websiteURL' | 'descriptionOfCompany' | 'dateJoined'>
    )> }
  )> }
);

export type CreateJobAdMutationVariables = Exact<{
  input: CreateJobAdInput;
}>;


export type CreateJobAdMutation = (
  { __typename?: 'Mutation' }
  & { createJobAd?: Maybe<(
    { __typename?: 'CreateJobAdPayload' }
    & { jobAd?: Maybe<(
      { __typename?: 'JobAd' }
      & Pick<JobAd, 'id' | 'created' | 'jobTitle' | 'jobDescription' | 'skills' | 'certificatesAndLicences' | 'contractType' | 'minimumEducation' | 'minimumEnglishProficiency' | 'salaryType' | 'salaryMin' | 'salaryMax'>
      & { shifts: Array<Maybe<(
        { __typename?: 'Shift' }
        & Pick<Shift, 'startDay' | 'endDay' | 'startTime' | 'endTime'>
      )>>, locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'city' | 'address' | 'industrialHub'>
      )>, company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type CreateReviewMutationVariables = Exact<{
  reviewInput: CreateReviewInput;
  salaryInput: CreateSalaryInput;
}>;


export type CreateReviewMutation = (
  { __typename?: 'Mutation' }
  & { createReview?: Maybe<(
    { __typename?: 'CreateReviewPayload' }
    & { review?: Maybe<(
      { __typename?: 'Review' }
      & Pick<Review, 'id' | 'title' | 'jobTitle' | 'numberOfMonthsWorked' | 'contractType' | 'employmentStatus' | 'pros' | 'cons' | 'wouldRecommendToOtherJobSeekers' | 'additionalComments' | 'created' | 'upvotes' | 'downvotes'>
      & { company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'name'>
      )>, location: (
        { __typename?: 'Location' }
        & Pick<Location, 'address' | 'city' | 'industrialHub'>
      ), currentUserVote?: Maybe<(
        { __typename?: 'Vote' }
        & Pick<Vote, 'isUpvote'>
      )> }
    )> }
  )>, createSalary?: Maybe<(
    { __typename?: 'CreateSalaryPayload' }
    & { salary?: Maybe<(
      { __typename?: 'Salary' }
      & Pick<Salary, 'id' | 'jobTitle' | 'incomeType' | 'incomeAmount' | 'created'>
      & { company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'name'>
      )>, location: (
        { __typename?: 'Location' }
        & Pick<Location, 'address' | 'city' | 'industrialHub'>
      ) }
    )> }
  )> }
);

export type CreateSalaryMutationVariables = Exact<{
  input: CreateSalaryInput;
}>;


export type CreateSalaryMutation = (
  { __typename?: 'Mutation' }
  & { createSalary?: Maybe<(
    { __typename?: 'CreateSalaryPayload' }
    & { salary?: Maybe<(
      { __typename?: 'Salary' }
      & Pick<Salary, 'id' | 'jobTitle' | 'incomeType' | 'incomeAmount' | 'created'>
      & { company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'name'>
      )>, location: (
        { __typename?: 'Location' }
        & Pick<Location, 'address' | 'city' | 'industrialHub'>
      ) }
    )> }
  )> }
);

export type EmployerPageResourcesQueryVariables = Exact<{
  audienceType: Scalars['String'];
}>;


export type EmployerPageResourcesQuery = (
  { __typename?: 'Query' }
  & { highlightedResources: Array<(
    { __typename?: 'Resource' }
    & Pick<Resource, 'slug' | 'title' | 'subtitle' | 'resourceImageURL' | 'topicName' | 'publishDate'>
  )> }
);

export type JobAdPageQueryVariables = Exact<{
  jobAdId: Scalars['ID'];
}>;


export type JobAdPageQuery = (
  { __typename?: 'Query' }
  & { jobAd?: Maybe<(
    { __typename?: 'JobAd' }
    & JobPostingFragment
  )> }
);

export type JobApplicationSubmittedQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type JobApplicationSubmittedQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  )> }
);

export type ResourceLikeMutationVariables = Exact<{
  input: ResourceLikeInput;
}>;


export type ResourceLikeMutation = (
  { __typename?: 'Mutation' }
  & { resourceLike?: Maybe<(
    { __typename?: 'ResourceLikePayload' }
    & { resource?: Maybe<(
      { __typename?: 'Resource' }
      & Pick<Resource, 'id' | 'slug' | 'title' | 'subtitle' | 'body' | 'resourceImageURL' | 'topicName' | 'authorId' | 'numberOfLikes' | 'isLikedByCurrentUser' | 'publishDate'>
    )> }
  )> }
);

export type ResourcePageQueryVariables = Exact<{
  id: Scalars['ID'];
  currentPageNum: Scalars['Int'];
}>;


export type ResourcePageQuery = (
  { __typename?: 'Query' }
  & { resource?: Maybe<(
    { __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'slug' | 'title' | 'subtitle' | 'body' | 'resourceImageURL' | 'topicName' | 'numberOfLikes' | 'isLikedByCurrentUser' | 'publishDate'>
    & { author?: Maybe<(
      { __typename?: 'ResourceAuthor' }
      & Pick<ResourceAuthor, 'authorName' | 'authorCompanyName' | 'authorImageURL' | 'authorBio' | 'contactPhoneNumber' | 'contactEmail' | 'websiteURL' | 'location'>
    )> }
  )>, searchRecentResources: (
    { __typename?: 'ResourceConnection' }
    & Pick<ResourceConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Resource' }
      & Pick<Resource, 'slug' | 'title' | 'resourceImageURL' | 'topicName' | 'publishDate'>
    )> }
  ), resourceTopics: Array<(
    { __typename?: 'ResourceTopic' }
    & Pick<ResourceTopic, 'topicName' | 'iconImageURL'>
  )> }
);

export type ResourcesByTopicPageQueryVariables = Exact<{
  id: Scalars['ID'];
  currentPageNum: Scalars['Int'];
  audienceType: Scalars['String'];
}>;


export type ResourcesByTopicPageQuery = (
  { __typename?: 'Query' }
  & { searchResourcesByTopic: (
    { __typename?: 'ResourceConnection' }
    & Pick<ResourceConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Resource' }
      & Pick<Resource, 'slug' | 'title' | 'resourceImageURL' | 'topicName' | 'publishDate'>
    )> }
  ) }
);

export type ResourcesIndexPageQueryVariables = Exact<{
  currentPageNum: Scalars['Int'];
  audienceType: Scalars['String'];
}>;


export type ResourcesIndexPageQuery = (
  { __typename?: 'Query' }
  & { searchRecentResources: (
    { __typename?: 'ResourceConnection' }
    & Pick<ResourceConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Resource' }
      & Pick<Resource, 'slug' | 'title' | 'resourceImageURL' | 'topicName' | 'publishDate'>
    )> }
  ), resourceTopics: Array<(
    { __typename?: 'ResourceTopic' }
    & Pick<ResourceTopic, 'topicName' | 'iconImageURL'>
  )>, highlightedResources: Array<(
    { __typename?: 'Resource' }
    & Pick<Resource, 'slug' | 'title' | 'resourceImageURL' | 'topicName' | 'publishDate'>
  )> }
);

export type ShowJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type ShowJobsQuery = (
  { __typename?: 'Query' }
  & { searchJobAds: (
    { __typename?: 'JobAdConnection' }
    & { nodes: Array<(
      { __typename?: 'JobAd' }
      & Pick<JobAd, 'id'>
      & JobPostingFragment
    )> }
  ) }
);

export type CreateUserProfileMutationVariables = Exact<{
  input: UserProfileInput;
}>;


export type CreateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { createUserProfile?: Maybe<(
    { __typename?: 'UserProfilePayload' }
    & { userProfile?: Maybe<(
      { __typename?: 'UserProfile' }
      & UserProfileDataFragment
    )> }
  )> }
);

export type UpdateUserProfileMutationVariables = Exact<{
  input: UserProfileInput;
}>;


export type UpdateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile?: Maybe<(
    { __typename?: 'UserProfilePayload' }
    & { userProfile?: Maybe<(
      { __typename?: 'UserProfile' }
      & UserProfileDataFragment
    )> }
  )> }
);

export type GetUserProfileDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserProfileDataQuery = (
  { __typename?: 'Query' }
  & { userProfile?: Maybe<(
    { __typename?: 'UserProfile' }
    & UserProfileDataFragment
  )> }
);

export type UserProfileDataFragment = (
  { __typename?: 'UserProfile' }
  & Pick<UserProfile, 'id' | 'fullName' | 'phoneNumber' | 'city' | 'email' | 'neighborhood' | 'skills' | 'certificatesAndLicences' | 'highestLevelOfEducation' | 'englishProficiency' | 'availability' | 'availabilityComments'>
  & { workExperiences?: Maybe<Array<Maybe<(
    { __typename?: 'WorkExperience' }
    & Pick<WorkExperience, 'jobTitle' | 'companyName' | 'city' | 'startDate' | 'endDate' | 'experienceDescription'>
  )>>> }
);

export type UserPageQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserPageQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'username' | 'role'>
  )> }
);

export const CompanyProfileOverviewSectionFragmentDoc = gql`
    fragment CompanyProfileOverviewSection on Company {
  name
  descriptionOfCompany
}
    `;
export const CompanyRatingsComponentFragmentDoc = gql`
    fragment CompanyRatingsComponent on Company {
  avgStarRatings {
    healthAndSafety
    managerRelationship
    workEnvironment
    benefits
    overallSatisfaction
  }
  percentRecommended
  avgNumMonthsWorked
}
    `;
export const VoteButtonsFragmentDoc = gql`
    fragment VoteButtons on Review {
  id
  currentUserVote {
    id
    isUpvote
  }
}
    `;
export const CompanyProfileReviewFragmentDoc = gql`
    fragment CompanyProfileReview on Review {
  id
  title
  jobTitle
  location {
    city
    address
    industrialHub
  }
  numberOfMonthsWorked
  pros
  cons
  wouldRecommendToOtherJobSeekers
  starRatings {
    healthAndSafety
    managerRelationship
    workEnvironment
    benefits
    overallSatisfaction
  }
  additionalComments
  created
  ...VoteButtons
}
    ${VoteButtonsFragmentDoc}`;
export const CompanyProfileReviewsSectionFragmentDoc = gql`
    fragment CompanyProfileReviewsSection on Company {
  name
  ...CompanyRatingsComponent
  reviews(pageSize: 1) {
    ...CompanyProfileReview
  }
  numReviews
}
    ${CompanyRatingsComponentFragmentDoc}
${CompanyProfileReviewFragmentDoc}`;
export const CompanyProfileSalaryStatsFragmentDoc = gql`
    fragment CompanyProfileSalaryStats on CompanySalaryStats {
  jobTitle
  totalAvgPay
  totalMaxPay
  totalMinPay
  numSalariesJobTitle
}
    `;
export const CompanyProfileSalariesSectionFragmentDoc = gql`
    fragment CompanyProfileSalariesSection on Company {
  name
  salaryStats {
    ...CompanyProfileSalaryStats
  }
  numSalaries
}
    ${CompanyProfileSalaryStatsFragmentDoc}`;
export const JobPostingFragmentDoc = gql`
    fragment JobPosting on JobAd {
  id
  created
  jobTitle
  jobDescription
  skills
  certificatesAndLicences
  contractType
  minimumEducation
  minimumEnglishProficiency
  externalJobPostURL
  isArchived
  shifts {
    startDay
    endDay
    startTime
    endTime
  }
  locations {
    city
    address
    industrialHub
  }
  salaryType
  salaryMin
  salaryMax
  company {
    id
    ...CompanyProfileOverviewSection
    ...CompanyProfileReviewsSection
    ...CompanyProfileSalariesSection
    descriptionOfCompany
    numEmployees
    industry
    locations {
      city
      address
      industrialHub
    }
    websiteURL
    companyIconURL
  }
}
    ${CompanyProfileOverviewSectionFragmentDoc}
${CompanyProfileReviewsSectionFragmentDoc}
${CompanyProfileSalariesSectionFragmentDoc}`;
export const CompanyProfileJobsSectionFragmentDoc = gql`
    fragment CompanyProfileJobsSection on Company {
  name
  jobAds(pageSize: 1) {
    ...JobPosting
  }
  numJobAds
}
    ${JobPostingFragmentDoc}`;
export const CompanySearchResultFragmentDoc = gql`
    fragment CompanySearchResult on Company {
  id
  name
  avgStarRatings {
    overallSatisfaction
  }
  locations {
    city
    address
    industrialHub
  }
  industry
  numEmployees
  descriptionOfCompany
  numReviews
  numJobAds
  numSalaries
  companyIconURL
}
    `;
export const UserProfileDataFragmentDoc = gql`
    fragment UserProfileData on UserProfile {
  id
  fullName
  phoneNumber
  city
  email
  neighborhood
  workExperiences {
    jobTitle
    companyName
    city
    startDate
    endDate
    experienceDescription
  }
  skills
  certificatesAndLicences
  highestLevelOfEducation
  englishProficiency
  availability
  availabilityComments
}
    `;
export const CompanyNameInputDocument = gql`
    query CompanyNameInput($partialCompanyName: String!) {
  companyNameSuggestions(partialCompanyName: $partialCompanyName)
}
    `;

/**
 * __useCompanyNameInputQuery__
 *
 * To run a query within a React component, call `useCompanyNameInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyNameInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyNameInputQuery({
 *   variables: {
 *      partialCompanyName: // value for 'partialCompanyName'
 *   },
 * });
 */
export function useCompanyNameInputQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyNameInputQuery, CompanyNameInputQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyNameInputQuery, CompanyNameInputQueryVariables>(CompanyNameInputDocument, baseOptions);
      }
export function useCompanyNameInputLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyNameInputQuery, CompanyNameInputQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyNameInputQuery, CompanyNameInputQueryVariables>(CompanyNameInputDocument, baseOptions);
        }
export type CompanyNameInputQueryHookResult = ReturnType<typeof useCompanyNameInputQuery>;
export type CompanyNameInputLazyQueryHookResult = ReturnType<typeof useCompanyNameInputLazyQuery>;
export type CompanyNameInputQueryResult = ApolloReactCommon.QueryResult<CompanyNameInputQuery, CompanyNameInputQueryVariables>;
export const SearchSuggestionsDocument = gql`
    query SearchSuggestions($partialCompanyName: String!) {
  companyNameSuggestions(partialCompanyName: $partialCompanyName, onlyCompaniesWithProfiles: true)
}
    `;

/**
 * __useSearchSuggestionsQuery__
 *
 * To run a query within a React component, call `useSearchSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSuggestionsQuery({
 *   variables: {
 *      partialCompanyName: // value for 'partialCompanyName'
 *   },
 * });
 */
export function useSearchSuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchSuggestionsQuery, SearchSuggestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchSuggestionsQuery, SearchSuggestionsQueryVariables>(SearchSuggestionsDocument, baseOptions);
      }
export function useSearchSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchSuggestionsQuery, SearchSuggestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchSuggestionsQuery, SearchSuggestionsQueryVariables>(SearchSuggestionsDocument, baseOptions);
        }
export type SearchSuggestionsQueryHookResult = ReturnType<typeof useSearchSuggestionsQuery>;
export type SearchSuggestionsLazyQueryHookResult = ReturnType<typeof useSearchSuggestionsLazyQuery>;
export type SearchSuggestionsQueryResult = ApolloReactCommon.QueryResult<SearchSuggestionsQuery, SearchSuggestionsQueryVariables>;
export const VoteButtonsDocument = gql`
    mutation voteButtons($input: CastVoteInput!) {
  castVote(input: $input) {
    vote {
      id
      isUpvote
    }
  }
}
    `;
export type VoteButtonsMutationFn = ApolloReactCommon.MutationFunction<VoteButtonsMutation, VoteButtonsMutationVariables>;

/**
 * __useVoteButtonsMutation__
 *
 * To run a mutation, you first call `useVoteButtonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteButtonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteButtonsMutation, { data, loading, error }] = useVoteButtonsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoteButtonsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VoteButtonsMutation, VoteButtonsMutationVariables>) {
        return ApolloReactHooks.useMutation<VoteButtonsMutation, VoteButtonsMutationVariables>(VoteButtonsDocument, baseOptions);
      }
export type VoteButtonsMutationHookResult = ReturnType<typeof useVoteButtonsMutation>;
export type VoteButtonsMutationResult = ApolloReactCommon.MutationResult<VoteButtonsMutation>;
export type VoteButtonsMutationOptions = ApolloReactCommon.BaseMutationOptions<VoteButtonsMutation, VoteButtonsMutationVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    id
    username
    role
    company {
      id
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const ApplyToJobAdDocument = gql`
    mutation applyToJobAd($input: ApplyToJobAdInput!) {
  applyToJobAd(input: $input) {
    success
  }
}
    `;
export type ApplyToJobAdMutationFn = ApolloReactCommon.MutationFunction<ApplyToJobAdMutation, ApplyToJobAdMutationVariables>;

/**
 * __useApplyToJobAdMutation__
 *
 * To run a mutation, you first call `useApplyToJobAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyToJobAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyToJobAdMutation, { data, loading, error }] = useApplyToJobAdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyToJobAdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApplyToJobAdMutation, ApplyToJobAdMutationVariables>) {
        return ApolloReactHooks.useMutation<ApplyToJobAdMutation, ApplyToJobAdMutationVariables>(ApplyToJobAdDocument, baseOptions);
      }
export type ApplyToJobAdMutationHookResult = ReturnType<typeof useApplyToJobAdMutation>;
export type ApplyToJobAdMutationResult = ApolloReactCommon.MutationResult<ApplyToJobAdMutation>;
export type ApplyToJobAdMutationOptions = ApolloReactCommon.BaseMutationOptions<ApplyToJobAdMutation, ApplyToJobAdMutationVariables>;
export const GetJobTitleAndCompanyIdDocument = gql`
    query getJobTitleAndCompanyId($jobAdId: ID!) {
  jobAd(id: $jobAdId) {
    jobTitle
    company {
      id
      numReviews
    }
  }
}
    `;

/**
 * __useGetJobTitleAndCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetJobTitleAndCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobTitleAndCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobTitleAndCompanyIdQuery({
 *   variables: {
 *      jobAdId: // value for 'jobAdId'
 *   },
 * });
 */
export function useGetJobTitleAndCompanyIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJobTitleAndCompanyIdQuery, GetJobTitleAndCompanyIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJobTitleAndCompanyIdQuery, GetJobTitleAndCompanyIdQueryVariables>(GetJobTitleAndCompanyIdDocument, baseOptions);
      }
export function useGetJobTitleAndCompanyIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJobTitleAndCompanyIdQuery, GetJobTitleAndCompanyIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJobTitleAndCompanyIdQuery, GetJobTitleAndCompanyIdQueryVariables>(GetJobTitleAndCompanyIdDocument, baseOptions);
        }
export type GetJobTitleAndCompanyIdQueryHookResult = ReturnType<typeof useGetJobTitleAndCompanyIdQuery>;
export type GetJobTitleAndCompanyIdLazyQueryHookResult = ReturnType<typeof useGetJobTitleAndCompanyIdLazyQuery>;
export type GetJobTitleAndCompanyIdQueryResult = ApolloReactCommon.QueryResult<GetJobTitleAndCompanyIdQuery, GetJobTitleAndCompanyIdQueryVariables>;
export const CompanyProfileSummaryDocument = gql`
    query CompanyProfileSummary($companyId: ID!) {
  company(id: $companyId) {
    id
    name
    numEmployees
    industry
    locations {
      city
      address
      industrialHub
    }
    avgStarRatings {
      overallSatisfaction
    }
    websiteURL
    companyIconURL
  }
}
    `;

/**
 * __useCompanyProfileSummaryQuery__
 *
 * To run a query within a React component, call `useCompanyProfileSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProfileSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProfileSummaryQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyProfileSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyProfileSummaryQuery, CompanyProfileSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyProfileSummaryQuery, CompanyProfileSummaryQueryVariables>(CompanyProfileSummaryDocument, baseOptions);
      }
export function useCompanyProfileSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyProfileSummaryQuery, CompanyProfileSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyProfileSummaryQuery, CompanyProfileSummaryQueryVariables>(CompanyProfileSummaryDocument, baseOptions);
        }
export type CompanyProfileSummaryQueryHookResult = ReturnType<typeof useCompanyProfileSummaryQuery>;
export type CompanyProfileSummaryLazyQueryHookResult = ReturnType<typeof useCompanyProfileSummaryLazyQuery>;
export type CompanyProfileSummaryQueryResult = ApolloReactCommon.QueryResult<CompanyProfileSummaryQuery, CompanyProfileSummaryQueryVariables>;
export const CompanyProfileJobTabDocument = gql`
    query CompanyProfileJobTab($companyId: ID!, $pageNum: Int!, $pageSize: Int!) {
  company(id: $companyId) {
    id
    jobAds(pageNum: $pageNum, pageSize: $pageSize) {
      ...JobPosting
    }
    numJobAds
  }
}
    ${JobPostingFragmentDoc}`;

/**
 * __useCompanyProfileJobTabQuery__
 *
 * To run a query within a React component, call `useCompanyProfileJobTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProfileJobTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProfileJobTabQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      pageNum: // value for 'pageNum'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useCompanyProfileJobTabQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyProfileJobTabQuery, CompanyProfileJobTabQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyProfileJobTabQuery, CompanyProfileJobTabQueryVariables>(CompanyProfileJobTabDocument, baseOptions);
      }
export function useCompanyProfileJobTabLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyProfileJobTabQuery, CompanyProfileJobTabQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyProfileJobTabQuery, CompanyProfileJobTabQueryVariables>(CompanyProfileJobTabDocument, baseOptions);
        }
export type CompanyProfileJobTabQueryHookResult = ReturnType<typeof useCompanyProfileJobTabQuery>;
export type CompanyProfileJobTabLazyQueryHookResult = ReturnType<typeof useCompanyProfileJobTabLazyQuery>;
export type CompanyProfileJobTabQueryResult = ApolloReactCommon.QueryResult<CompanyProfileJobTabQuery, CompanyProfileJobTabQueryVariables>;
export const CompanyProfileOverviewTabDocument = gql`
    query CompanyProfileOverviewTab($companyId: ID!) {
  company(id: $companyId) {
    id
    ...CompanyProfileOverviewSection
    ...CompanyProfileReviewsSection
    ...CompanyProfileJobsSection
    ...CompanyProfileSalariesSection
  }
}
    ${CompanyProfileOverviewSectionFragmentDoc}
${CompanyProfileReviewsSectionFragmentDoc}
${CompanyProfileJobsSectionFragmentDoc}
${CompanyProfileSalariesSectionFragmentDoc}`;

/**
 * __useCompanyProfileOverviewTabQuery__
 *
 * To run a query within a React component, call `useCompanyProfileOverviewTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProfileOverviewTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProfileOverviewTabQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyProfileOverviewTabQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyProfileOverviewTabQuery, CompanyProfileOverviewTabQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyProfileOverviewTabQuery, CompanyProfileOverviewTabQueryVariables>(CompanyProfileOverviewTabDocument, baseOptions);
      }
export function useCompanyProfileOverviewTabLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyProfileOverviewTabQuery, CompanyProfileOverviewTabQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyProfileOverviewTabQuery, CompanyProfileOverviewTabQueryVariables>(CompanyProfileOverviewTabDocument, baseOptions);
        }
export type CompanyProfileOverviewTabQueryHookResult = ReturnType<typeof useCompanyProfileOverviewTabQuery>;
export type CompanyProfileOverviewTabLazyQueryHookResult = ReturnType<typeof useCompanyProfileOverviewTabLazyQuery>;
export type CompanyProfileOverviewTabQueryResult = ApolloReactCommon.QueryResult<CompanyProfileOverviewTabQuery, CompanyProfileOverviewTabQueryVariables>;
export const CompanyProfileReviewTabDocument = gql`
    query CompanyProfileReviewTab($companyId: ID!, $pageNum: Int!, $pageSize: Int!) {
  company(id: $companyId) {
    id
    name
    ...CompanyRatingsComponent
    reviews(pageNum: $pageNum, pageSize: $pageSize) {
      ...CompanyProfileReview
    }
    numReviews
  }
}
    ${CompanyRatingsComponentFragmentDoc}
${CompanyProfileReviewFragmentDoc}`;

/**
 * __useCompanyProfileReviewTabQuery__
 *
 * To run a query within a React component, call `useCompanyProfileReviewTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProfileReviewTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProfileReviewTabQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      pageNum: // value for 'pageNum'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useCompanyProfileReviewTabQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyProfileReviewTabQuery, CompanyProfileReviewTabQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyProfileReviewTabQuery, CompanyProfileReviewTabQueryVariables>(CompanyProfileReviewTabDocument, baseOptions);
      }
export function useCompanyProfileReviewTabLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyProfileReviewTabQuery, CompanyProfileReviewTabQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyProfileReviewTabQuery, CompanyProfileReviewTabQueryVariables>(CompanyProfileReviewTabDocument, baseOptions);
        }
export type CompanyProfileReviewTabQueryHookResult = ReturnType<typeof useCompanyProfileReviewTabQuery>;
export type CompanyProfileReviewTabLazyQueryHookResult = ReturnType<typeof useCompanyProfileReviewTabLazyQuery>;
export type CompanyProfileReviewTabQueryResult = ApolloReactCommon.QueryResult<CompanyProfileReviewTabQuery, CompanyProfileReviewTabQueryVariables>;
export const CompanyProfileSalaryTabDocument = gql`
    query CompanyProfileSalaryTab($companyId: ID!) {
  company(id: $companyId) {
    id
    name
    salaryStats {
      ...CompanyProfileSalaryStats
    }
    numSalaries
  }
}
    ${CompanyProfileSalaryStatsFragmentDoc}`;

/**
 * __useCompanyProfileSalaryTabQuery__
 *
 * To run a query within a React component, call `useCompanyProfileSalaryTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProfileSalaryTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProfileSalaryTabQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyProfileSalaryTabQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyProfileSalaryTabQuery, CompanyProfileSalaryTabQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyProfileSalaryTabQuery, CompanyProfileSalaryTabQueryVariables>(CompanyProfileSalaryTabDocument, baseOptions);
      }
export function useCompanyProfileSalaryTabLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyProfileSalaryTabQuery, CompanyProfileSalaryTabQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyProfileSalaryTabQuery, CompanyProfileSalaryTabQueryVariables>(CompanyProfileSalaryTabDocument, baseOptions);
        }
export type CompanyProfileSalaryTabQueryHookResult = ReturnType<typeof useCompanyProfileSalaryTabQuery>;
export type CompanyProfileSalaryTabLazyQueryHookResult = ReturnType<typeof useCompanyProfileSalaryTabLazyQuery>;
export type CompanyProfileSalaryTabQueryResult = ApolloReactCommon.QueryResult<CompanyProfileSalaryTabQuery, CompanyProfileSalaryTabQueryVariables>;
export const CompanySearchPageDocument = gql`
    query companySearchPage($searchText: String!, $pageNum: Int!, $pageSize: Int!) {
  searchCompanies(searchText: $searchText, pageNum: $pageNum, pageSize: $pageSize) {
    totalCount
    nodes {
      ...CompanySearchResult
    }
  }
}
    ${CompanySearchResultFragmentDoc}`;

/**
 * __useCompanySearchPageQuery__
 *
 * To run a query within a React component, call `useCompanySearchPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySearchPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySearchPageQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      pageNum: // value for 'pageNum'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useCompanySearchPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanySearchPageQuery, CompanySearchPageQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanySearchPageQuery, CompanySearchPageQueryVariables>(CompanySearchPageDocument, baseOptions);
      }
export function useCompanySearchPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanySearchPageQuery, CompanySearchPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanySearchPageQuery, CompanySearchPageQueryVariables>(CompanySearchPageDocument, baseOptions);
        }
export type CompanySearchPageQueryHookResult = ReturnType<typeof useCompanySearchPageQuery>;
export type CompanySearchPageLazyQueryHookResult = ReturnType<typeof useCompanySearchPageLazyQuery>;
export type CompanySearchPageQueryResult = ApolloReactCommon.QueryResult<CompanySearchPageQuery, CompanySearchPageQueryVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    company {
      id
      name
      contactEmail
      yearEstablished
      numEmployees
      industry
      contactPhoneNumber
      websiteURL
      descriptionOfCompany
      dateJoined
    }
  }
}
    `;
export type CreateCompanyMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = ApolloReactCommon.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const CreateJobAdDocument = gql`
    mutation createJobAd($input: CreateJobAdInput!) {
  createJobAd(input: $input) {
    jobAd {
      id
      created
      jobTitle
      jobDescription
      skills
      certificatesAndLicences
      contractType
      minimumEducation
      minimumEnglishProficiency
      shifts {
        startDay
        endDay
        startTime
        endTime
      }
      locations {
        city
        address
        industrialHub
      }
      salaryType
      salaryMin
      salaryMax
      company {
        id
        name
      }
    }
  }
}
    `;
export type CreateJobAdMutationFn = ApolloReactCommon.MutationFunction<CreateJobAdMutation, CreateJobAdMutationVariables>;

/**
 * __useCreateJobAdMutation__
 *
 * To run a mutation, you first call `useCreateJobAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobAdMutation, { data, loading, error }] = useCreateJobAdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJobAdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateJobAdMutation, CreateJobAdMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateJobAdMutation, CreateJobAdMutationVariables>(CreateJobAdDocument, baseOptions);
      }
export type CreateJobAdMutationHookResult = ReturnType<typeof useCreateJobAdMutation>;
export type CreateJobAdMutationResult = ApolloReactCommon.MutationResult<CreateJobAdMutation>;
export type CreateJobAdMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateJobAdMutation, CreateJobAdMutationVariables>;
export const CreateReviewDocument = gql`
    mutation createReview($reviewInput: CreateReviewInput!, $salaryInput: CreateSalaryInput!) {
  createReview(input: $reviewInput) {
    review {
      id
      company {
        name
      }
      title
      jobTitle
      location {
        address
        city
        industrialHub
      }
      numberOfMonthsWorked
      contractType
      employmentStatus
      pros
      cons
      wouldRecommendToOtherJobSeekers
      additionalComments
      created
      upvotes
      downvotes
      currentUserVote {
        isUpvote
      }
    }
  }
  createSalary(input: $salaryInput) {
    salary {
      id
      company {
        name
      }
      jobTitle
      location {
        address
        city
        industrialHub
      }
      incomeType
      incomeAmount
      created
    }
  }
}
    `;
export type CreateReviewMutationFn = ApolloReactCommon.MutationFunction<CreateReviewMutation, CreateReviewMutationVariables>;

/**
 * __useCreateReviewMutation__
 *
 * To run a mutation, you first call `useCreateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReviewMutation, { data, loading, error }] = useCreateReviewMutation({
 *   variables: {
 *      reviewInput: // value for 'reviewInput'
 *      salaryInput: // value for 'salaryInput'
 *   },
 * });
 */
export function useCreateReviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReviewMutation, CreateReviewMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReviewMutation, CreateReviewMutationVariables>(CreateReviewDocument, baseOptions);
      }
export type CreateReviewMutationHookResult = ReturnType<typeof useCreateReviewMutation>;
export type CreateReviewMutationResult = ApolloReactCommon.MutationResult<CreateReviewMutation>;
export type CreateReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReviewMutation, CreateReviewMutationVariables>;
export const CreateSalaryDocument = gql`
    mutation createSalary($input: CreateSalaryInput!) {
  createSalary(input: $input) {
    salary {
      id
      company {
        name
      }
      jobTitle
      location {
        address
        city
        industrialHub
      }
      incomeType
      incomeAmount
      created
    }
  }
}
    `;
export type CreateSalaryMutationFn = ApolloReactCommon.MutationFunction<CreateSalaryMutation, CreateSalaryMutationVariables>;

/**
 * __useCreateSalaryMutation__
 *
 * To run a mutation, you first call `useCreateSalaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalaryMutation, { data, loading, error }] = useCreateSalaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSalaryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSalaryMutation, CreateSalaryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSalaryMutation, CreateSalaryMutationVariables>(CreateSalaryDocument, baseOptions);
      }
export type CreateSalaryMutationHookResult = ReturnType<typeof useCreateSalaryMutation>;
export type CreateSalaryMutationResult = ApolloReactCommon.MutationResult<CreateSalaryMutation>;
export type CreateSalaryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSalaryMutation, CreateSalaryMutationVariables>;
export const EmployerPageResourcesDocument = gql`
    query employerPageResources($audienceType: String!) {
  highlightedResources(audienceType: $audienceType) {
    slug
    title
    subtitle
    resourceImageURL
    topicName
    publishDate
  }
}
    `;

/**
 * __useEmployerPageResourcesQuery__
 *
 * To run a query within a React component, call `useEmployerPageResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployerPageResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployerPageResourcesQuery({
 *   variables: {
 *      audienceType: // value for 'audienceType'
 *   },
 * });
 */
export function useEmployerPageResourcesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployerPageResourcesQuery, EmployerPageResourcesQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployerPageResourcesQuery, EmployerPageResourcesQueryVariables>(EmployerPageResourcesDocument, baseOptions);
      }
export function useEmployerPageResourcesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployerPageResourcesQuery, EmployerPageResourcesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployerPageResourcesQuery, EmployerPageResourcesQueryVariables>(EmployerPageResourcesDocument, baseOptions);
        }
export type EmployerPageResourcesQueryHookResult = ReturnType<typeof useEmployerPageResourcesQuery>;
export type EmployerPageResourcesLazyQueryHookResult = ReturnType<typeof useEmployerPageResourcesLazyQuery>;
export type EmployerPageResourcesQueryResult = ApolloReactCommon.QueryResult<EmployerPageResourcesQuery, EmployerPageResourcesQueryVariables>;
export const JobAdPageDocument = gql`
    query JobAdPage($jobAdId: ID!) {
  jobAd(id: $jobAdId) {
    ...JobPosting
  }
}
    ${JobPostingFragmentDoc}`;

/**
 * __useJobAdPageQuery__
 *
 * To run a query within a React component, call `useJobAdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobAdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobAdPageQuery({
 *   variables: {
 *      jobAdId: // value for 'jobAdId'
 *   },
 * });
 */
export function useJobAdPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobAdPageQuery, JobAdPageQueryVariables>) {
        return ApolloReactHooks.useQuery<JobAdPageQuery, JobAdPageQueryVariables>(JobAdPageDocument, baseOptions);
      }
export function useJobAdPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobAdPageQuery, JobAdPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobAdPageQuery, JobAdPageQueryVariables>(JobAdPageDocument, baseOptions);
        }
export type JobAdPageQueryHookResult = ReturnType<typeof useJobAdPageQuery>;
export type JobAdPageLazyQueryHookResult = ReturnType<typeof useJobAdPageLazyQuery>;
export type JobAdPageQueryResult = ApolloReactCommon.QueryResult<JobAdPageQuery, JobAdPageQueryVariables>;
export const JobApplicationSubmittedDocument = gql`
    query JobApplicationSubmitted($companyId: ID!) {
  company(id: $companyId) {
    name
  }
}
    `;

/**
 * __useJobApplicationSubmittedQuery__
 *
 * To run a query within a React component, call `useJobApplicationSubmittedQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobApplicationSubmittedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobApplicationSubmittedQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useJobApplicationSubmittedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobApplicationSubmittedQuery, JobApplicationSubmittedQueryVariables>) {
        return ApolloReactHooks.useQuery<JobApplicationSubmittedQuery, JobApplicationSubmittedQueryVariables>(JobApplicationSubmittedDocument, baseOptions);
      }
export function useJobApplicationSubmittedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobApplicationSubmittedQuery, JobApplicationSubmittedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobApplicationSubmittedQuery, JobApplicationSubmittedQueryVariables>(JobApplicationSubmittedDocument, baseOptions);
        }
export type JobApplicationSubmittedQueryHookResult = ReturnType<typeof useJobApplicationSubmittedQuery>;
export type JobApplicationSubmittedLazyQueryHookResult = ReturnType<typeof useJobApplicationSubmittedLazyQuery>;
export type JobApplicationSubmittedQueryResult = ApolloReactCommon.QueryResult<JobApplicationSubmittedQuery, JobApplicationSubmittedQueryVariables>;
export const ResourceLikeDocument = gql`
    mutation resourceLike($input: ResourceLikeInput!) {
  resourceLike(input: $input) {
    resource {
      id
      slug
      title
      subtitle
      body
      resourceImageURL
      topicName
      authorId
      numberOfLikes
      isLikedByCurrentUser
      publishDate
    }
  }
}
    `;
export type ResourceLikeMutationFn = ApolloReactCommon.MutationFunction<ResourceLikeMutation, ResourceLikeMutationVariables>;

/**
 * __useResourceLikeMutation__
 *
 * To run a mutation, you first call `useResourceLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResourceLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resourceLikeMutation, { data, loading, error }] = useResourceLikeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResourceLikeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResourceLikeMutation, ResourceLikeMutationVariables>) {
        return ApolloReactHooks.useMutation<ResourceLikeMutation, ResourceLikeMutationVariables>(ResourceLikeDocument, baseOptions);
      }
export type ResourceLikeMutationHookResult = ReturnType<typeof useResourceLikeMutation>;
export type ResourceLikeMutationResult = ApolloReactCommon.MutationResult<ResourceLikeMutation>;
export type ResourceLikeMutationOptions = ApolloReactCommon.BaseMutationOptions<ResourceLikeMutation, ResourceLikeMutationVariables>;
export const ResourcePageDocument = gql`
    query resourcePage($id: ID!, $currentPageNum: Int!) {
  resource(id: $id) {
    id
    slug
    title
    subtitle
    body
    resourceImageURL
    topicName
    author {
      authorName
      authorCompanyName
      authorImageURL
      authorBio
      contactPhoneNumber
      contactEmail
      websiteURL
      location
    }
    numberOfLikes
    isLikedByCurrentUser
    publishDate
  }
  searchRecentResources(pageNum: $currentPageNum) {
    totalCount
    nodes {
      slug
      title
      resourceImageURL
      topicName
      publishDate
    }
  }
  resourceTopics {
    topicName
    iconImageURL
  }
}
    `;

/**
 * __useResourcePageQuery__
 *
 * To run a query within a React component, call `useResourcePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      currentPageNum: // value for 'currentPageNum'
 *   },
 * });
 */
export function useResourcePageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResourcePageQuery, ResourcePageQueryVariables>) {
        return ApolloReactHooks.useQuery<ResourcePageQuery, ResourcePageQueryVariables>(ResourcePageDocument, baseOptions);
      }
export function useResourcePageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResourcePageQuery, ResourcePageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResourcePageQuery, ResourcePageQueryVariables>(ResourcePageDocument, baseOptions);
        }
export type ResourcePageQueryHookResult = ReturnType<typeof useResourcePageQuery>;
export type ResourcePageLazyQueryHookResult = ReturnType<typeof useResourcePageLazyQuery>;
export type ResourcePageQueryResult = ApolloReactCommon.QueryResult<ResourcePageQuery, ResourcePageQueryVariables>;
export const ResourcesByTopicPageDocument = gql`
    query resourcesByTopicPage($id: ID!, $currentPageNum: Int!, $audienceType: String!) {
  searchResourcesByTopic(id: $id, pageNum: $currentPageNum, audienceType: $audienceType) {
    totalCount
    nodes {
      slug
      title
      resourceImageURL
      topicName
      publishDate
    }
  }
}
    `;

/**
 * __useResourcesByTopicPageQuery__
 *
 * To run a query within a React component, call `useResourcesByTopicPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesByTopicPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesByTopicPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      currentPageNum: // value for 'currentPageNum'
 *      audienceType: // value for 'audienceType'
 *   },
 * });
 */
export function useResourcesByTopicPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResourcesByTopicPageQuery, ResourcesByTopicPageQueryVariables>) {
        return ApolloReactHooks.useQuery<ResourcesByTopicPageQuery, ResourcesByTopicPageQueryVariables>(ResourcesByTopicPageDocument, baseOptions);
      }
export function useResourcesByTopicPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResourcesByTopicPageQuery, ResourcesByTopicPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResourcesByTopicPageQuery, ResourcesByTopicPageQueryVariables>(ResourcesByTopicPageDocument, baseOptions);
        }
export type ResourcesByTopicPageQueryHookResult = ReturnType<typeof useResourcesByTopicPageQuery>;
export type ResourcesByTopicPageLazyQueryHookResult = ReturnType<typeof useResourcesByTopicPageLazyQuery>;
export type ResourcesByTopicPageQueryResult = ApolloReactCommon.QueryResult<ResourcesByTopicPageQuery, ResourcesByTopicPageQueryVariables>;
export const ResourcesIndexPageDocument = gql`
    query resourcesIndexPage($currentPageNum: Int!, $audienceType: String!) {
  searchRecentResources(pageNum: $currentPageNum, audienceType: $audienceType) {
    totalCount
    nodes {
      slug
      title
      resourceImageURL
      topicName
      publishDate
    }
  }
  resourceTopics(audienceType: $audienceType) {
    topicName
    iconImageURL
  }
  highlightedResources(audienceType: $audienceType) {
    slug
    title
    resourceImageURL
    topicName
    publishDate
  }
}
    `;

/**
 * __useResourcesIndexPageQuery__
 *
 * To run a query within a React component, call `useResourcesIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesIndexPageQuery({
 *   variables: {
 *      currentPageNum: // value for 'currentPageNum'
 *      audienceType: // value for 'audienceType'
 *   },
 * });
 */
export function useResourcesIndexPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResourcesIndexPageQuery, ResourcesIndexPageQueryVariables>) {
        return ApolloReactHooks.useQuery<ResourcesIndexPageQuery, ResourcesIndexPageQueryVariables>(ResourcesIndexPageDocument, baseOptions);
      }
export function useResourcesIndexPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResourcesIndexPageQuery, ResourcesIndexPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResourcesIndexPageQuery, ResourcesIndexPageQueryVariables>(ResourcesIndexPageDocument, baseOptions);
        }
export type ResourcesIndexPageQueryHookResult = ReturnType<typeof useResourcesIndexPageQuery>;
export type ResourcesIndexPageLazyQueryHookResult = ReturnType<typeof useResourcesIndexPageLazyQuery>;
export type ResourcesIndexPageQueryResult = ApolloReactCommon.QueryResult<ResourcesIndexPageQuery, ResourcesIndexPageQueryVariables>;
export const ShowJobsDocument = gql`
    query showJobs {
  searchJobAds {
    nodes {
      id
      ...JobPosting
    }
  }
}
    ${JobPostingFragmentDoc}`;

/**
 * __useShowJobsQuery__
 *
 * To run a query within a React component, call `useShowJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useShowJobsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ShowJobsQuery, ShowJobsQueryVariables>) {
        return ApolloReactHooks.useQuery<ShowJobsQuery, ShowJobsQueryVariables>(ShowJobsDocument, baseOptions);
      }
export function useShowJobsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShowJobsQuery, ShowJobsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ShowJobsQuery, ShowJobsQueryVariables>(ShowJobsDocument, baseOptions);
        }
export type ShowJobsQueryHookResult = ReturnType<typeof useShowJobsQuery>;
export type ShowJobsLazyQueryHookResult = ReturnType<typeof useShowJobsLazyQuery>;
export type ShowJobsQueryResult = ApolloReactCommon.QueryResult<ShowJobsQuery, ShowJobsQueryVariables>;
export const CreateUserProfileDocument = gql`
    mutation createUserProfile($input: UserProfileInput!) {
  createUserProfile(input: $input) {
    userProfile {
      ...UserProfileData
    }
  }
}
    ${UserProfileDataFragmentDoc}`;
export type CreateUserProfileMutationFn = ApolloReactCommon.MutationFunction<CreateUserProfileMutation, CreateUserProfileMutationVariables>;

/**
 * __useCreateUserProfileMutation__
 *
 * To run a mutation, you first call `useCreateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserProfileMutation, { data, loading, error }] = useCreateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserProfileMutation, CreateUserProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserProfileMutation, CreateUserProfileMutationVariables>(CreateUserProfileDocument, baseOptions);
      }
export type CreateUserProfileMutationHookResult = ReturnType<typeof useCreateUserProfileMutation>;
export type CreateUserProfileMutationResult = ApolloReactCommon.MutationResult<CreateUserProfileMutation>;
export type CreateUserProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserProfileMutation, CreateUserProfileMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($input: UserProfileInput!) {
  updateUserProfile(input: $input) {
    userProfile {
      ...UserProfileData
    }
  }
}
    ${UserProfileDataFragmentDoc}`;
export type UpdateUserProfileMutationFn = ApolloReactCommon.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, baseOptions);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = ApolloReactCommon.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const GetUserProfileDataDocument = gql`
    query getUserProfileData {
  userProfile {
    ...UserProfileData
  }
}
    ${UserProfileDataFragmentDoc}`;

/**
 * __useGetUserProfileDataQuery__
 *
 * To run a query within a React component, call `useGetUserProfileDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserProfileDataQuery, GetUserProfileDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserProfileDataQuery, GetUserProfileDataQueryVariables>(GetUserProfileDataDocument, baseOptions);
      }
export function useGetUserProfileDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserProfileDataQuery, GetUserProfileDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserProfileDataQuery, GetUserProfileDataQueryVariables>(GetUserProfileDataDocument, baseOptions);
        }
export type GetUserProfileDataQueryHookResult = ReturnType<typeof useGetUserProfileDataQuery>;
export type GetUserProfileDataLazyQueryHookResult = ReturnType<typeof useGetUserProfileDataLazyQuery>;
export type GetUserProfileDataQueryResult = ApolloReactCommon.QueryResult<GetUserProfileDataQuery, GetUserProfileDataQueryVariables>;
export const UserPageDocument = gql`
    query userPage($userId: ID!) {
  user(id: $userId) {
    username
    role
  }
}
    `;

/**
 * __useUserPageQuery__
 *
 * To run a query within a React component, call `useUserPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPageQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserPageQuery, UserPageQueryVariables>) {
        return ApolloReactHooks.useQuery<UserPageQuery, UserPageQueryVariables>(UserPageDocument, baseOptions);
      }
export function useUserPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserPageQuery, UserPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserPageQuery, UserPageQueryVariables>(UserPageDocument, baseOptions);
        }
export type UserPageQueryHookResult = ReturnType<typeof useUserPageQuery>;
export type UserPageLazyQueryHookResult = ReturnType<typeof useUserPageLazyQuery>;
export type UserPageQueryResult = ApolloReactCommon.QueryResult<UserPageQuery, UserPageQueryVariables>;