import React from "react";

export default () => (
	<div className="lds-container">
		<div className="lds-ring">
			<div />
			<div />
			<div />
			<div />
		</div>
	</div>
);
